import React, { useCallback, useEffect, useState } from 'react';
import Inspector from 'react-json-inspector';
import 'react-json-inspector/json-inspector.css';
import './JSONViewer.global.styl';
import { Tabs, Input } from 'antd';
import { getMeasurementFullData } from '../../../../api/measurementService';
import Spinner from '../../../shared/Spinner';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { useCustomMaterials } from '../../../../hooks/useProductData';

export const JSONTreeView: React.FunctionComponent<{ data: Object }> = (props) => {
    const { data } = props;
    const formatMessage = useFormatMessage();
    const [search, setSearch] = useState('');
    return (
        <Tabs
            defaultActiveKey="1"
            className="json-viewer-wrapper"
            items={[
                {
                    key: '1',
                    label: formatMessage({ id: 'DataView.JSON.Navigator' }),
                    children: (
                        <>
                            <Input
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={formatMessage({ id: 'DataView.JSON.Filter.Placeholder' })}
                            />
                            <Inspector
                                data={data}
                                id={null}
                                filterOptions={{ ignoreCase: true }}
                                query={search}
                                validateQuery={() => true}
                            />
                        </>
                    ),
                },
                {
                    key: '2',
                    label: formatMessage({ id: 'DataView.JSON.RawOutput' }),
                    children: (
                        <Input.TextArea
                            readOnly
                            value={JSON.stringify(data, null, 4)}
                            autoSize
                            style={{ maxHeight: '100%' }}
                        />
                    ),
                },
            ]}
        />
    );
};

export const MeasurementJSONView: React.FunctionComponent<{ measurementID: string }> = (props) => {
    const { measurementID } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [output, setOutput] = useState<Object>({});

    const handleFetchMeasurementFullData = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getMeasurementFullData({ measurementID });
            if (data) {
                setOutput(data);
            }
        } finally {
            setIsLoading(false);
        }
    }, [measurementID]);

    useEffect(() => {
        handleFetchMeasurementFullData();
    }, [handleFetchMeasurementFullData]);

    if (isLoading) {
        return <Spinner size="default" />;
    }
    return <JSONTreeView data={output} />;
};

export const CustomMaterialJSONView: React.FunctionComponent<{ customMaterialID: string }> = (props) => {
    const { customMaterialID } = props;
    const customMaterials = useCustomMaterials();
    const data = customMaterials?.[customMaterialID] ?? {};
    return <JSONTreeView data={data} />;
};
