import React, { useEffect, useState } from 'react';
import { App } from 'antd';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { getLocalItem, saveLocalItem } from '../../utils/storageUtils';
import useCurrentUserID from '../../hooks/useCurrentUser';
import NotificationContext from './NotificationContext';
import { oneSignalHelper } from '../../utils/oneSignalHelper';

const NotificationProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isPushNotificationEnabled, setIsPushNotificationEnabled] = useState(false);
    const formatMessage = useFormatMessage();
    const userID = useCurrentUserID();
    const { message } = App.useApp();

    useEffect(() => {
        // isInitialized check is needed to prevent reinitialize from formatMessage changes
        if (isInitialized) return;

        const initializeOneSignal = async () => {
            try {
                await oneSignalHelper.init(formatMessage);
                setIsInitialized(true);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('Error initializing OneSignal: ', e);
            }
        };

        initializeOneSignal();
    }, [formatMessage, isInitialized]);

    // set up native browser listener for when the permission changes
    useEffect(() => {
        if (!userID || !isInitialized) return () => {};
        const handlePermissionChange = (permission: boolean) => {
            if (permission) {
                oneSignalHelper.addTag(userID);
                setIsPushNotificationEnabled(true);
            } else {
                setIsPushNotificationEnabled(false);
                if (!getLocalItem('onesignal-notification-prompt')) {
                    // Not triggered from one signal, need to setup manually to prevent checking
                    const record = { value: '"dismissed"', timestamp: Date.now() };
                    saveLocalItem('onesignal-notification-prompt', JSON.stringify(record));
                }
            }
        };

        oneSignalHelper.addPermissionsListener(handlePermissionChange);

        return () => {
            oneSignalHelper.removePermissionsListener(handlePermissionChange);
        };
    }, [formatMessage, isInitialized, message, userID]);

    // Check for permission for first time
    useEffect(() => {
        if (!userID || !isInitialized) return;

        const checkAndRequestForPushNotification = async () => {
            try {
                if (oneSignalHelper.getPermission()) {
                    // If notifications are enabled, setup user tags and update state
                    oneSignalHelper.addTag(userID);
                    setIsPushNotificationEnabled(true);
                    return;
                }

                if (!getLocalItem('onesignal-notification-prompt')) {
                    oneSignalHelper.requestPushNotification();
                }

                setIsPushNotificationEnabled(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error checking or requesting push notification:', error);
            }
        };

        checkAndRequestForPushNotification();
    }, [formatMessage, isInitialized, message, userID]);

    return (
        <NotificationContext.Provider value={{ isPushNotificationEnabled }}>{children}</NotificationContext.Provider>
    );
};

export default NotificationProvider;
