import OneSignal from 'react-onesignal';
// @ts-ignore
import { Convertor } from 'encryption-utils';
import config from '../config';
import { FormatMessageFunc } from '../types';

const ONE_SIGNAL_TAG_KEY = 'userID';

class OneSignalHelper {
    init = async (formatMessage: FormatMessageFunc) => {
        const oneSignalAppID =
            location.hostname === 'localhost' ? '17a0a66d-da79-42d2-81af-fb4302000ac6' : config.ONE_SIGNAL_APP_ID;
        const oneSignalSafariID =
            config.NODE_ENV === 'development'
                ? 'web.onesignal.auto.276b1587-9df2-49f1-886b-c818bd2e0d3c'
                : config.ONE_SIGNAL_SAFARI_ID;
        return OneSignal.init({
            appId: oneSignalAppID ?? '',
            safari_web_id: oneSignalSafariID,
            allowLocalhostAsSecureOrigin: location.hostname === 'localhost',
            persistNotification: false,
            promptOptions: {
                slidedown: {
                    enabled: true,
                    type: 'push',
                    autoPrompt: false,
                    actionMessage: formatMessage({ id: 'Notifications.Request.Message' }),
                    acceptButton: formatMessage({ id: 'Notifications.Request.Allow' }),
                    cancelButton: formatMessage({ id: 'Notifications.Request.NoThanks' }),
                },
            },
        });
    };

    requestPushNotification = () => {
        try {
            if (OneSignal.Notifications.isPushSupported()) {
                OneSignal.Slidedown.promptPush({ force: true });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('OneSignal requestPushNotification error:', e);
        }
    };

    addTag = (userID: number) =>
        OneSignal.User.addTag(ONE_SIGNAL_TAG_KEY, Convertor.bytesToBase64(Convertor.sha256(userID.toString())));

    removeTag = () => OneSignal.User.removeTag(ONE_SIGNAL_TAG_KEY);

    // if native permission is denied, browser blocks the OneSignal prompt from showing up
    isPushSupported = () =>
        OneSignal.Notifications.isPushSupported() && OneSignal.Notifications.permissionNative !== 'denied';

    // this event fires before the push notification is displayed
    addNotificationsListener = (listener: any) =>
        OneSignal.Notifications.addEventListener('foregroundWillDisplay', listener);

    removeNotificationsListener = (listener: any) =>
        OneSignal.Notifications.removeEventListener('foregroundWillDisplay', listener);

    // listens to browser's native permission request
    addPermissionsListener = (listener: (permission: boolean) => void) =>
        OneSignal.Notifications.addEventListener('permissionChange', listener);

    removePermissionsListener = (listener: (permission: boolean) => void) =>
        OneSignal.Notifications.removeEventListener('permissionChange', listener);

    getPermission = () => OneSignal.Notifications.permission;
}

export const oneSignalHelper = new OneSignalHelper();
