import { createContext } from 'react';

interface NotificationContextState {
    isPushNotificationEnabled: boolean;
}

const NotificationContext = createContext<NotificationContextState>({
    isPushNotificationEnabled: false,
});

export default NotificationContext;
