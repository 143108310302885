import React, { useCallback, useState } from 'react';
import { Badge } from 'antd';
import { ReactComponent as IconNotification } from '../../images/iconNotification.svg';
import IconButton from '../shared/Buttons/IconButton';
import colors from '../../styles/colors.json';
import NotificationDrawer from './NotificationDrawer';
import { useNotificationUnreadCount } from './useNotifcationData';

const NotificationButton: React.FunctionComponent = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const toggleDrawer = useCallback(() => {
        setDrawerVisible((visible) => !visible);
    }, []);

    const unreadCount = useNotificationUnreadCount();

    return (
        <>
            <IconButton style={{ width: 40, height: 40, marginRight: 16 }} onClick={toggleDrawer}>
                <Badge
                    count={unreadCount}
                    overflowCount={Number.MAX_VALUE}
                    style={{ backgroundColor: colors.red700, color: colors.gray200 }}
                >
                    <IconNotification height={24} width={22} />
                </Badge>
            </IconButton>
            <NotificationDrawer visible={drawerVisible} toggleDrawer={toggleDrawer} />
        </>
    );
};

export default NotificationButton;
