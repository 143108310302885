import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import UserComponent, { title as titleUserComponent } from '../Common/User';
import MeasurementPresets, { title as titleMeasurementPresets } from './shared/MeasurementPresets';
import UtilitiesLine, { title as titleUtilitiesLine } from './GPRSoil/UtilitiesLine';
import Objects, { title as titleObjects } from './GPR/Objects';
import Markers, { title as titleMarkers } from './shared/Markers';
import PointsOfInterest, { title as titlePointsOfInterest } from './GPRSoil/PointsOfInterest';
import ScanDistance, { title as TitleScanDistance } from './GPR/ScanDistance';
import SavedSnapshots from './SavedSnapshots';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { ProductCode, ProductModel } from '../../../../types/proceq';

const GPRSoilReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(data, 'measurement.type');
    const isMetric = get(data, 'settings.0.content.settings.display.unit') === 'Metric';

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data || !scanType) {
        return null;
    }

    const derivedProps: DerivedProps & { productModel: ProductModel } = {
        product,
        productModel,
        scanType,
        isMetric,
        convert: convertor,
        showTitle: isStandalone,
    };

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={titleUserComponent}>
                <UserComponent data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="measurement" header={titleMeasurementPresets}>
                <MeasurementPresets data={data} {...derivedProps} />
            </ReadingPanel>

            <SavedSnapshots data={data} {...derivedProps} />

            {data.objects && (
                <>
                    {product !== ProductCode.GPR_MOUNTED && (
                        <>
                            <ReadingPanel key="object" header={titleObjects}>
                                <Objects data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="utilitiesLine" header={titleUtilitiesLine}>
                                <UtilitiesLine data={data} {...derivedProps} />
                            </ReadingPanel>
                            <ReadingPanel key="markers" header={titleMarkers}>
                                <Markers data={data} {...derivedProps} />
                            </ReadingPanel>
                        </>
                    )}
                    <ReadingPanel key="pointsOfInterest" header={titlePointsOfInterest}>
                        <PointsOfInterest data={data} {...derivedProps} />
                    </ReadingPanel>
                </>
            )}
            <ReadingPanel key="scanDistance" header={<TitleScanDistance {...derivedProps} />}>
                <ScanDistance data={data} {...derivedProps} />
            </ReadingPanel>
        </ReadingContainer>
    );
};

export default GPRSoilReadings;
