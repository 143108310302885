import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cleanUpCredentials, redirectEagleIDLogout } from '../../utils/authUtils';
import Spinner from '../shared/Spinner';
import { useCurrentUserID } from '../../hooks/useCurrentUser';
import * as authService from '../../api/authService';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { logDeviceType } from '../../api/analyticsEventService';
import { oneSignalHelper } from '../../utils/oneSignalHelper';

const AuthLogout: React.FunctionComponent = () => {
    const formatMessage = useFormatMessage();
    const userID = useCurrentUserID();
    const dispatch = useDispatch();

    useEffect(() => {
        const action = async () => {
            try {
                if (userID) {
                    oneSignalHelper.removeTag();
                    await logDeviceType('logout');
                    await authService.oAuthLogout();
                }
            } finally {
                await cleanUpCredentials(dispatch);
                redirectEagleIDLogout();
            }
        };
        action();
    }, [userID, dispatch]);

    return (
        <Spinner style={{ maxHeight: 'none' }} tip={formatMessage({ id: 'Auth.LoggingOut' })}>
            <div style={{ width: '100vw' }} />
        </Spinner>
    );
};

export default AuthLogout;
