import React from 'react';
import { Table } from 'antd';
import FormattedMessage from '../../../../../localization/FormatMessage';
import styles from '../../DataViewers.styl';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { MeasurementFullData } from '../../../../../types/measurement';
import { getLocationCoordinates, MeasurementObjectWithInfo, sortObjectData } from '../GPR/Objects';
import { filterReadings } from '../GPR/ScanDistance';
import { DerivedProps } from '../../SharedTypes';
import { ScanType } from '../../../../../types/proceq';
import WrapText from '../../../../shared/WrapText';

export const title = <FormattedMessage id="PointsOfInterest" />;

const PointsOfInterest: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    convert,
    showTitle,
}) => {
    if (scanType !== ScanType.FreePath) return null;

    const filteredReadings = filterReadings(data, scanType);
    const dataSource: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'poi')
        .map((object) => {
            const reading = filteredReadings.find((r) => r.id === object.rID);
            return {
                ...object,
                ...getLocationCoordinates(object),
                readingContent: reading
                    ? {
                          ...reading.content,
                          sequenceNo: reading.sequenceNo,
                      }
                    : {},
            };
        })
        .sort(sortObjectData);

    if (data.objects.length === 0) return null;

    const columnsRaw: {
        [key: string]: any;
        title: React.ReactNode;
    }[] = [
        {
            title: <FormattedMessage id="App.HTML.GPR_SOIL.Objects.Category" />,
            dataIndex: ['content', 'category', 'name'],
            width: 150,
            render: (text: string) => (text ? <WrapText text={text} /> : '-'),
        },
        {
            title: <FormattedMessage id="App.HTML.GPR_SOIL.Objects.Easting" />,
            render: (text: string) => convert(text, 'GPR_SOIL.CSV.LocationCoordinates'),
            dataIndex: ['convertedCoordinateX'],
            width: 120,
        },
        {
            title: <FormattedMessage id="App.HTML.GPR_SOIL.Objects.Northing" />,
            render: (text: string) => convert(text, 'GPR_SOIL.CSV.LocationCoordinates'),
            dataIndex: ['convertedCoordinateY'],
            width: 120,
        },
        {
            title: <FormattedMessage id="App.HTML.GPR_SOIL.Objects.GroundElevation" />,
            render: (text: string) => convert(text, 'GPR_SOIL.CSV.LocationCoordinates'),
            dataIndex: ['convertedCoordinateZ'],
            width: 130,
        },
        {
            title: <FormattedMessage id="Set Depth" />,
            render: (text: string) => convert(text, 'GPR_SOIL.Logbook Panel.Set Depth'),
            dataIndex: ['content', 'location', 'depthMeter'],
            width: 120,
        },
        {
            title: <FormattedMessage id="Set Size" />,
            render: (text: string) => convert(text, 'GPR_SOIL.Logbook Panel.Set Size'),
            dataIndex: ['content', 'sizeMeter'],
            width: 120,
        },
        {
            title: <FormattedMessage id="Comment" />,
            dataIndex: ['content', 'comment'],
            render: (text: string) => (text ? <WrapText text={text} /> : '-'),
        },
    ];

    return (
        <div className="table-markers">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={columnsRaw}
                rowKey="id"
                dataSource={dataSource}
                pagination={false}
                size="small"
                bordered
                locale={tableLocaleSetting}
            />
        </div>
    );
};

export default PointsOfInterest;
