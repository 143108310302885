import React from 'react';
import { get, has } from 'lodash';
import { Table } from 'antd';

import styles from '../../DataViewers.styl';
import { MULTI_ARRAY_PRODUCT_MODEL, ProductModel, ScanType } from '../../../../../types/proceq';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { DerivedProps } from '../../SharedTypes';
import { MeasurementFullData } from '../../../../../types/measurement';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';

export const filterReadings = (data: MeasurementFullData, scanType: ScanType) => {
    const isAreaScan = scanType === ScanType.AreaScan;
    const isFreePath = scanType === ScanType.FreePath;
    const isSuperlineScan = scanType === ScanType.SuperlineScan;
    const s = get(data, 'settings[0].content');
    let nYLines = 0,
        nXLines = 0;
    let spacing = 0,
        maxScanLength = 0;
    let gridSpacingX = 0,
        gridSpacingY = 0,
        gridSizeX = 0,
        gridSizeY = 0;
    let scanPattern = 'uniform';
    if (has(s, 'settings.preset.scanPattern')) {
        scanPattern = s.settings.preset.scanPattern;
    }
    const scanFrequency =
        isFreePath || isSuperlineScan
            ? s.settings.preset.scanFrequency.value ?? s.settings.preset.scanFrequency.scansPerMeter
            : 1;
    const isUniform = scanPattern.toLowerCase() === 'uniform';

    if (has(s, 'settings.preset.scanModeParameters.areaScanParameters.numberOfVerticalLines')) {
        nYLines = s.settings.preset.scanModeParameters.areaScanParameters.numberOfVerticalLines;
        nXLines = s.settings.preset.scanModeParameters.areaScanParameters.numberOfHorizontalLines;
    } else if (isAreaScan) {
        maxScanLength = s.settings.preset.scanModeParameters.areaScanParameters.maxScanLength;
        spacing = s.settings.preset.scanModeParameters.areaScanParameters.spacing;
        nYLines = Math.round(maxScanLength / spacing) + 1;
        nXLines = nYLines;
    }

    if (has(s, 'settings.preset.scanModeParameters.areaScanParameters.gridSpacingX')) {
        gridSpacingX = s.settings.preset.scanModeParameters.areaScanParameters.gridSpacingX;
        gridSpacingY = s.settings.preset.scanModeParameters.areaScanParameters.gridSpacingY;
        gridSizeX = (nYLines - 1) * gridSpacingX;
        gridSizeY = (nXLines - 1) * gridSpacingY;
    } else {
        gridSpacingX = spacing;
        gridSpacingY = spacing;
        gridSizeX = maxScanLength;
        gridSizeY = maxScanLength;
    }

    const processedReadings = [];
    const r = data.readings.filter((reading) => reading.type.toLowerCase() !== 'gpsposition');

    // GS9000 measurements may be free path but saved under superlineFreePathStatus
    const lineStatus =
        data.measurement.content.areaScanStatus?.lineStatus ??
        data.measurement.content.freePathStatus?.lineStatus ??
        data.measurement.content.superlineFreePathStatus?.lineStatus;

    // put line status to an array.
    const lineStatusMap: { [key: string]: number } = {};
    const lineStatusIndexMap: { [key: string]: number } = {};

    if (lineStatus) {
        for (const l of lineStatus) {
            if (isFreePath) {
                const distance = (l.numberOfAScans - 1) / scanFrequency;
                lineStatusMap[`${l.position.dimension}:${l.position.sequence}`] = distance;
                lineStatusIndexMap[`${l.position.dimension}:${l.position.sequence}`] =
                    MULTI_ARRAY_PRODUCT_MODEL.includes(data.measurement.productModel.toUpperCase() as ProductModel)
                        ? l.position.sequence
                        : l.id;
            } else {
                lineStatusMap[`${l.position.dimension}:${l.position.sequence}`] = l.measuredDistanceMeter;
            }
        }
    }

    for (const reading of r) {
        const readingSequence = reading.content.sequence;
        const readingDimension = reading.content.dimension;
        const readingNew = { ...reading, startx: 0, starty: 0 };
        let sequenceNo = 1;
        let startX = 0.0;
        let startY = 0.0;
        if (isAreaScan) {
            if (readingNew.content.dimension === 'X') {
                sequenceNo = nYLines + reading.content.sequence;
            } else {
                sequenceNo = reading.content.sequence;
            }
        } else if (isFreePath) {
            sequenceNo = lineStatusIndexMap[`${readingDimension}:${readingSequence}`] ?? undefined;
            if (sequenceNo === undefined) continue;
        }

        readingNew.sequenceNo = sequenceNo;
        readingNew.measuredDistanceMeter = lineStatusMap[`${reading.content.dimension}:${reading.content.sequence}`];
        /*
            For LineScan, there'll be single entry in the lineStatus.
            So, if it's lineScan reading it's measuredDistanceMeter will give the correct scan distance
        */
        if (
            scanType === ScanType.LineScan &&
            reading.measuredDistanceMeter === undefined &&
            lineStatus !== undefined &&
            lineStatus[0] !== undefined
        ) {
            readingNew.measuredDistanceMeter = lineStatus[0].measuredDistanceMeter;
        }

        // for superline scan the total distance is only saved in logs
        if (isSuperlineScan) {
            const closeFileLog = data.logs.find((log) => log.type === 'closeFile');
            readingNew.measuredDistanceMeter = closeFileLog?.content?.distance ?? '-';
        }

        if (data.productFamily.toUpperCase() === 'GPR_SOIL') {
            if (sequenceNo > 1) {
                if (readingDimension === 'X') {
                    startY = (readingSequence - 1) * gridSpacingY;
                    if (isUniform) {
                        startX = 0;
                    } else if (readingSequence % 2 === 0) {
                        startX = gridSizeX;
                    } else {
                        startX = 0;
                    }
                } else {
                    startX = (readingSequence - 1) * gridSpacingX;
                    if (isUniform) {
                        startY = 0;
                    } else if (readingSequence % 2 === 0) {
                        startY = gridSizeY;
                    } else {
                        startY = 0;
                    }
                }
            }
            readingNew.startx = startX;
            readingNew.starty = startY;
        }

        processedReadings.push(readingNew);
    }
    processedReadings.sort((a, b) => a.sequenceNo - b.sequenceNo);
    return processedReadings;
};

export const title = ({ product, scanType, isMetric }: Pick<DerivedProps, 'product' | 'scanType' | 'isMetric'>) => (
    <ConvertedUnits
        id="App.HTML.GPR.ScanDistance"
        unitId={`${product}.CSV.Scan distance`}
        scanType={scanType}
        isMetric={isMetric}
    />
);

export const ScanDistance: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    product,
    scanType,
    isMetric,
    convert,
    showTitle,
}) => {
    /*
		construct data first
		1. fix sequence number
		2. sort data
	*/
    const filteredLines = filterReadings(data, scanType);
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const isFreePath = scanType === ScanType.FreePath;
    return (
        <Table
            title={
                showTitle
                    ? () => <span className={styles.main_header}>{title({ product, scanType, isMetric })}</span>
                    : undefined
            }
            className={[styles.table, 'table-scan-distance'].join(' ')}
            columns={[
                {
                    key: 1,
                    render: (_: string | number, record: (typeof filteredLines)[number]) => `Line ${record.sequenceNo}`,
                    width: 230,
                },
                {
                    key: 2,
                    dataIndex: 'measuredDistanceMeter',
                    render: (text: string | number) => convert(text, `${product}.CSV.Scan distance`),
                },
                {
                    key: 3,
                    dataIndex: 'startx',
                    render: (text: string | number) => convert(text, `${product}.CSV.Scan distance`),
                    enable: productModel === 'GPR_SOIL' && !isFreePath,
                },
                {
                    key: 4,
                    dataIndex: 'starty',
                    render: (text: string | number) => convert(text, `${product}.CSV.Scan distance`),
                    enable: productModel === 'GPR_SOIL' && !isFreePath,
                },
            ].filter((row) => row.enable === undefined || row.enable)}
            dataSource={filteredLines}
            rowKey="id"
            pagination={false}
            showHeader={false}
            size="small"
            locale={tableLocaleSetting}
        />
    );
};

export default ScanDistance;
